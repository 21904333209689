import React from 'react'
import PropTypes from 'prop-types';
import {
    Alert,
    Button,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Row,
} from 'reactstrap'

export default class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emailAddress: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    isValid = () => {
        return this.state.emailAddress && this.state.emailAddress.trim() !== '';
    }

    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({ [name]: value });
    }

    onSubmit = (event) => {
        event.preventDefault();
        if (this.isValid) {
            this.props.onSubmit(this.state.emailAddress);
        }
    }

    cancel = (event) => {
        event.preventDefault();
        this.props.onCancel();
    }

    render() {
        const alertStyle = {
            width: '100%',
            textAlign: 'center',
        }

        const successMessage = "Request received! If this is a recognized email address, you should receive a password reset email within 5-10 minutes. If you don't receive your email after this time, please contact MHD Support.";
        const errorMessage = "Sorry! Please contact MHD Support to reset your password.";

        return (
            <div>
                {this.props.showSuccess && !this.props.showError &&
                    <Row>
                        <Col>
                            <Alert color="success" style={alertStyle}>{successMessage}</Alert>
                            <Button color="primary" onClick={this.props.onCancel}>Return to Login</Button>
                        </Col>
                    </Row>
                }
                {!this.props.showSuccess && this.props.showError &&
                    <Row>
                        <Col>
                            <Alert color="danger" style={alertStyle}>{errorMessage}</Alert>
                            <Button color="primary" onClick={this.props.onCancel}>Return to Login</Button>
                        </Col>
                    </Row>
                }
                {!this.props.showSuccess && !this.props.showError &&
                    <div>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup row>
                                <Label className="fw-bold form-label" for="emailAddress" sm="3">USER EMAIL: </Label>
                                <Col sm="6">
                                    <Input type="email" id="emailAddress" name="emailAddress" placeholder="User Email Address"
                                        value={this.state.emailAddress} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <Button color="primary" disabled={!this.isValid()} onClick={this.onSendEmail} style={{ marginRight: "10px" }} type="submit">
                                        Generate Password Reset Email
                                    </Button>
                                    <Button color="primary" onClick={this.cancel}>Cancel</Button>
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                }
            </div>
        )
    }
}

ForgotPassword.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    showSuccess: PropTypes.bool,
    showError: PropTypes.bool,
}
