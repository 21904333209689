import { createStore, compose, applyMiddleware } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'connected-react-router';

import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import persistState from 'redux-sessionstorage';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';

import { clearAuthToken, setAuthToken, logoutUser } from './actions/authActions';

import rootReducer from './reducers'
import { publishError } from './actions/errorActions';
import * as actionTypes from './actions/actionTypes';
import authCookie from './lib/authCookie';

import history from './lib/history';

import * as Sentry from "@sentry/react";

const keysToPersist = [
	'activePatient',
	'appliedPathways',
	'appointment',
	'availability',
	'availabilitySearch',
	'customFields',
	'auth',
	'careOrder',
	'config',
	'decisionSupport',
	'guidedResponse',
	'session'];

const client = axios.create({
	responseType: 'json',
	headers: { 'X-Requested-With': 'XMLHttpRequest' }
});

const axiosMiddlewareConfig = {
	interceptors: {
		request: [
			function ({ getState }, req) {
				const authState = getState().auth;
				if (authState.token) {
					req.headers.Authorization = `Bearer ${authState.token}`;
				}
				if (authState.productInstanceId > 0) {
					req.headers["ProductInstanceId"] = authState.productInstanceId;
				}
				if (authState.referralSiteId > 0) {
					req.headers["ReferralSiteId"] = authState.referralSiteId;
				}
				req.baseURL = `api/internal`;
				return req;
			}
		],
		response: [{
			success: function ({ dispatch, getSourceAction }, res) {
				const action = getSourceAction(res.config);
				if (
					[actionTypes.EXCHANGE_TOKEN, actionTypes.AUTHENTICATE_USER, actionTypes.VERIFY_TOKEN].some(
						(x) => x === action.type,
					)
				) {
					const token = res.data.token;
					const productInstanceId = res.data.config.id;
					const referralSiteId = res.data.referralSiteId;

					dispatch(
						setAuthToken({ token, productInstanceId, referralSiteId }),
					);
				}

				if (actionTypes.PROCESS_PRODUCT_INSTANCE === action.type) {
					dispatch(
						setAuthToken({
							...authCookie.get(),
							productInstanceId: res.data.config.id,
						}),
					);
				}

				return Promise.resolve(res);
			},
			error: ({ dispatch, getSourceAction }, error) => {
				const requestUrl = error.response.request.responseURL;
				const sourceAction = getSourceAction(error.config);
				const hasErrorData = typeof error?.response?.data === "object" && error.response.data !== null;

				if (hasErrorData) {
					if (error.response.data.detail) {
						// Handle normal ProblemDetails-adherent response shape (all endpoints should adhere to this going forward)
					}
					else if (error.response.data.userMessage) {
						error.response.data.detail = error.response.data.userMessage;
					}
					else if (!error.response.data.detail) {
						error.response.data.detail = "An error occurred while communicating with the service.";
					}
				} else {
					error.response.data = { detail: "An error occurred while communicating with the service." };
				}

				if (shouldAddErrorRef(sourceAction)) {
					error.response.data.detail =
						`An error occurred while communicating with the service. [ERROR REF: ${sourceAction?.payload?.request?.data?.correlationKey}]`;
				}

				dispatch(publishError(sourceAction.type, error.response.data));

				// Logout user if no authorization
				if (error.response && error.response.status === 401 && !requestUrl.includes("/login")) {
					dispatch(clearAuthToken());
					dispatch(logoutUser());
				}

				throw (error);
			}
		}]
	}
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	// Optionally pass options listed below
});

function configureStoreProd(initialState, history) {
	const reactRouterMiddleware = routerMiddleware(history)
	const middlewares = [
		thunk,
		reactRouterMiddleware,
		axiosMiddleware(client, axiosMiddlewareConfig)
	]
	let enhancer = compose(
		applyMiddleware(...middlewares),
		sentryReduxEnhancer,
		persistState(keysToPersist),
	);
	const store = createStore(
		rootReducer(history),
		fromJS(initialState),
		enhancer
	)

	return store
}

function configureStoreDev(initialState, history) {
	const reactRouterMiddleware = routerMiddleware(history)
	const middlewares = [
		thunk,
		reactRouterMiddleware,

		// Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
		reduxImmutableStateInvariant(),
		axiosMiddleware(client, axiosMiddlewareConfig)
	]

	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // add support for Redux dev tools
	let enhancer = composeEnhancers(
		applyMiddleware(...middlewares),
		persistState(keysToPersist)
	);
	const store = createStore(
		rootReducer(history),
		initialState,
		enhancer
	)

	return store
}

export default function configureStore(initialState = {}, history) {
	const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev
	const store = configureStore(initialState, history)
	return store
}

function shouldAddErrorRef(sourceAction) {
	return sourceAction.type === "APPOINTMENT_BOOK"
}

export function useAppDispatch() {
	return useDispatch();
}

export function useAppSelector(selector) {
	return useSelector(selector);
}

const initialState = {};
export const store = configureStore(initialState, history);