//Third Party Dependencies
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { Alert, Button, Col, Row } from 'reactstrap';

//MHD General Purpose libraries
import LoadingIndicator from '../../components/loadingIndicator';
import { useAppHistory, useDataApiState, useVoidApiState } from 'hooks';
import { useAppSelector } from 'store';

//Specific Business and UI components
import { PasswordConfigInfo, VerifyUserTokenInfo, VerifyUserTokenResult } from 'types';
import { internalService } from 'services';
import ResetPassword from '../../components/user/resetPassword';
import ErrorSummary from '../../components/common/errorSummary';

export const ResetPasswordView = () => {
    const [verifyUserTokenInfo, setVerifyUserTokenInfo] = useState<VerifyUserTokenInfo>();

    const [verifyTokenResult, setVerifyToken] = useDataApiState<VerifyUserTokenResult> ();
    const [resetUserPassword, setResetUserPassword] = useVoidApiState();

    const history = useAppHistory();
    const location = useLocation();
    const passwordConfig = useAppSelector((s: any) => s.config.passwordConfig) as PasswordConfigInfo;

    useEffect(() => {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as qs.ParsedQs;
        const verifyUserInfo: VerifyUserTokenInfo = {
            userId: params.userid as string,
            token: params.token as string,
        };
        setVerifyUserTokenInfo(verifyUserInfo);
        internalService.verifyToken(verifyUserInfo, setVerifyToken);
    }, [location.search, setVerifyToken]);

    const handleSubmit = (newPassword: string, confirmPassword: string) => {
        if (!verifyUserTokenInfo) {
            return;
        }
        const data = {
            newPassword,
            confirmPassword,
            token: verifyUserTokenInfo.token,
        };
        internalService.resetUserPassword(verifyUserTokenInfo.userId, data, setResetUserPassword);
    };

    const goToLogin = () => {
        history.navigate('/login');
    };

    const isPageLoading = verifyTokenResult.loading || resetUserPassword.processing;
    const isVerified = verifyTokenResult.data?.isVerified ?? false;
    const showResetPasswordForm = !isPageLoading && !resetUserPassword.completed && isVerified;
    const showResetPasswordSuccess = !isPageLoading && resetUserPassword.completed;

    const alertStyle = {
        width: '100%',
    };

    return (
        <div style={{ margin: "30px" }}>
            <h4 style={{ marginBottom: "20px" }}>Reset Password</h4>
            <div className="col-lg-5 col-md-7 col-sm-12" style={{ padding: "20px" }}>
                {isPageLoading &&
                    <LoadingIndicator />
                }

                {verifyTokenResult.error && <ErrorSummary error={verifyTokenResult.error} />}
                {resetUserPassword.error && <ErrorSummary error={resetUserPassword.error} />}

                {showResetPasswordForm &&
                    <ResetPassword passwordConfig={passwordConfig} onSubmit={handleSubmit} onCancel={goToLogin} email={verifyTokenResult.data?.email ?? ''} />
                }

                {showResetPasswordSuccess &&
                    <div>
                        <Row>
                            <Col>
                                <Alert color="success" style={alertStyle}>Your password has been reset.</Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button color="primary" onClick={goToLogin}>Back to Login</Button>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        </div>
    );
};

export default ResetPasswordView;
