/**
 * @format
 */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import * as routes from '../../routes';
import * as availabilitySearchActions from '../../actions/availabilitySearchActions';
import * as appointmentActions from '../../actions/appointmentActions';
import * as rd2RefactorActions from '../../actions/rd2RefactorActions';
import CancelAppointmentDetailsOverview from '../../components/appointment/cancelAppointmentDetailsOverview';
import { validateField } from '../../lib/validation';
import { ConfirmationModal } from '../../components/common/standardComponents';
import LoadingIndicator from '../../components/loadingIndicator';
import ErrorBoundary from '../../components/common/errorBoundary';
import { PATIENT_DETAILS, APPOINTMENT_DETAILS } from 'constants/actionReferrer';

export class AppointmentCancelView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapse: {
				emailConsent: false,
				email: false,
			},
			appointmentDetails: {},
			error: '',
			fieldValidation: {
				cancellationEmailOther: { dataType: 'Email', valid: true, required: false },
				reasonForCancellation: {
					dataType: 'String',
					valid: true,
					max: 200,
					required: this.props.schedulingConfig.showReasonForCancellation,
				},
			},
			isPageValid: true,
			isAppointmentCanceled: false,
			isCanceling: false,
			isCancelMode: true,
			isDetailsLoading: true,
			reasonForCancellation: '',
			cancellationEmailConsent: false,
			cancellationEmail: '',
			cancellationEmailOther: '',
			selectedLanguage: 'EN',
		};
	}

	loadAppointmentDetails = (referenceId) => {
		this.props.actions.appointment.getPreviousAppointmentByReferenceId(referenceId).then((response) => {
			if (response.error) {
				this.setState({
					error: 'Appointment not found and has been cancelled already.',
					isDetailsLoading: false,
				});
			} else {
				let appointmentDetails = response.payload.data;
				this.setState({
					appointmentDetails,
					isDetailsLoading: false,
				});
			}
		});
	};

	initializePatientNotifications = () => {
		if (this.props.emailCancellationEnabled && !this.props.enableCancellationEmailOptIn) {
			this.togglePatientNotifications('emailConsent');
			let event = {
				target: {
					name: 'cancellationEmailConsent',
					checked: true,
				},
			};

			this.handleCheckboxChange(
				event,
				this.props.patientEmail && this.props.patientEmail.trim() !== '' ? [] : ['cancellationEmailOther'],
			);
			let fieldValidation = { ...this.state.fieldValidation };
			fieldValidation.cancellationEmailOther.valid =
				this.props.patientEmail && this.props.patientEmail.trim() !== '' ? true : false;
			fieldValidation.cancellationEmailOther.required =
				this.props.patientEmail && this.props.patientEmail.trim() !== '' ? false : true;
			this.setState({
				fieldValidation,
			});
		}
	};

	handleCheckboxChange = (event, requiredFields) => {
		const { name, checked } = event.target;
		this.setState({ [name]: checked });
	};

	togglePatientNotifications = (area) => {
		let col = { ...this.state.collapse };
		col[area] = !col[area];
		this.setState({ collapse: col });
	};

	handleChange = (event, type) => {
		const { name, value, min, max } = event.target;
		let valObj = { ...this.state.fieldValidation };
		if (valObj[name]) {
			let isRequired = valObj[name].required;
			if (value && value.trim() !== '') {
				isRequired = true;
			}
			this.setState({ [name]: value, fieldValidation: valObj }, () => {
				valObj[name].valid = this.validateFieldAndForm(name, type, value, min, max, isRequired);
			});
		}
	};

	validateFieldAndForm = (name, type, value, minimum = null, maximum = null, required = true) => {
		let isFieldValid = validateField(name, type, value, minimum, maximum, required);

		let valObj = { ...this.state.fieldValidation };
		if (valObj[name]) {
			valObj[name].valid = isFieldValid;
			this.setState({ fieldValidation: valObj });
		}

		let validity = Object.keys(this.state.fieldValidation).every((field) => {
			return this.state.fieldValidation[field].valid === true;
		});

		this.setState({ isPageValid: validity ? true : false });

		return isFieldValid;
	};

	componentDidMount() {
		const referenceId = this.props.match.params.referenceId;
		this.loadAppointmentDetails(referenceId);
		this.initializePatientNotifications();
	}

	confirmCancellation = () => {
		let cancelRequest = {
			reasonForCancellation: this.state.reasonForCancellation,
			sendCancellationEmailToPatient: this.state.cancellationEmailConsent,
			email: this.state.cancellationEmailOther ? this.state.cancellationEmailOther : this.props.patientEmail,
			languageCode: this.state.selectedLanguage,
		};

		this.setState({ isCanceling: true });
		this.props.actions.rd2RefactorActions
			.cancelAppointment(this.props.previousAppointmentReferenceId, cancelRequest, this.props.token)
			.then((response) => {
				if (response.error) {
					this.setState({
						isCanceling: false,
						error: response.error.response.data || 'An error occurred while trying to cancel this appointment.',
					});
				} else {
					this.setState({
						isCanceling: false,
						isAppointmentCanceled: true,
					});
				}
			});
	};

	bookAnotherAppointment = () => {
		if (this.props.cancelRescheduleInfo && this.props.cancelRescheduleInfo.previousAppointmentReferenceId) {
			this.props.actions.rd2RefactorActions.clearPreviousAppointment();
		}
		this.props.actions.rd2RefactorActions.clearAgentInstructions();
		this.props.actions.rd2RefactorActions.startBookingProcess(
			this.props.activeCareOrder,
			this.props.decisionSupportOutput,
			this.props.availabilitySearchConfig,
			this.props.activePatient.details,
			this.props.useDecisionSupport,
		);
	};

	beginNewFlow = () => {
		this.props.actions.rd2RefactorActions.clearPreviousAppointment();
		this.props.dispatch(routes.patientSearch());
	};

	goBack = () => {
		let actionReferrer = this.props.cancelRescheduleInfo.actionReferrer;

		if (actionReferrer === PATIENT_DETAILS) {
			this.props.actions.rd2RefactorActions.clearPreviousAppointment();
			this.props.dispatch(routes.patientDetails(this.props.activePatient.id));
		} else if (actionReferrer === APPOINTMENT_DETAILS) {
			this.props.actions.rd2RefactorActions.clearPreviousAppointment();
			this.props.dispatch(routes.appointmentDetails(this.props.previousAppointmentReferenceId));
		} else {
			this.props.actions.rd2RefactorActions.clearPreviousAppointment();
			this.props.dispatch(routes.appointmentDetails(this.props.previousAppointmentReferenceId));
		}
	};

	onCancelReasonChange = (e) => {
		e.preventDefault();
		this.setState({
			reasonForCancellation: e.target.value,
		});
	};

	onSelectCancelReason = (e) => {
		e.preventDefault();
		let id = parseInt(e.target.value);
		if (id > 0) {
			let selection = this.props.systemCancelReasons.find((x) => x.id === id);
			this.setState({
				reasonForCancellation: selection.name,
			});
		} else {
			this.setState({
				reasonForCancellation: '',
			});
		}
	};

	resolveConfirmationEmailInstructions = () => {
		let result = 'No email address in patient profile.';

		let { emailCancellationEnabled, enableConfirmationEmailOptIn, patientEmail } = this.props;
		let isAutomatic = emailCancellationEnabled && !enableConfirmationEmailOptIn;
		let automaticallyText = isAutomatic ? 'automatically ' : '';

		if (patientEmail && patientEmail.trim() !== '') {
			result = `An email confirming cancellation will ${automaticallyText}be sent to ${patientEmail}. Optionally, you may override that email below.`;
		}
		return result;
	};

	render() {
		let isLoading = this.state.isCanceling || this.state.isDetailsLoading;
		let cancellationEmailInstructions = this.resolveConfirmationEmailInstructions();

		return (
			<ErrorBoundary childName="CancelRescheduleAppointment">
				<div className="container-fluid">
					<div>{this.state.error && <Alert color="danger">{this.state.error}</Alert>}</div>
					{isLoading && (
						<LoadingIndicator
							loadingMessage={this.state.isCanceling ? 'Canceling appointment. Please wait.' : 'Loading. Please wait.'}
						/>
					)}
					{!this.state.isAppointmentCanceled && !isLoading && (
						<CancelAppointmentDetailsOverview
							cancellationEmailConsent={this.state.cancellationEmailConsent}
							cancellationEmailInstructions={cancellationEmailInstructions}
							cancellationEmailOther={this.state.cancellationEmailOther}
							collapse={this.state.collapse}
							confirmCancellation={this.confirmCancellation}
							details={this.state.appointmentDetails}
							emailCancellationEnabled={this.props.notificationConfig.emailCancellationEnabled}
							enableCancellationEmailOptIn={this.props.enableCancellationEmailOptIn}
							enableCancellationEmailOther={true}
							fieldValidation={this.state.fieldValidation}
							goBack={this.goBack}
							handleChange={this.handleChange}
							handleCheckboxChange={this.handleCheckboxChange}
							onCancelReasonChange={this.onCancelReasonChange}
							onSelectCancelReason={this.onSelectCancelReason}
							patientCancellationEmailDisclaimer={this.props.notificationConfig.patientCancellationEmailDisclaimer}
							patientEmail={this.props.patientEmail}
							previousAppointmentDetails={this.props.cancelRescheduleInfo.previousAppointmentDetails}
							providerFieldConfig={this.props.providerFieldConfig}
							reasonForCancellation={this.state.reasonForCancellation}
							schedulingConfig={this.props.schedulingConfig}
							showInsuranceOnBookAppointment={this.props.schedulingConfig.showInsuranceOnBookAppointment}
							systemCancelReasons={this.props.systemCancelReasons}
							toggle={this.togglePatientNotifications}
						/>
					)}
					{this.state.isAppointmentCanceled && (
						<ConfirmationModal
							className="appointmentCanceledModal"
							title="Appointment Canceled"
							buttonLabel="OK"
							body="Appointment is successfully canceled."
							isOpen={this.state.isAppointmentCanceled}
							onConfirm={this.beginNewFlow}
						/>
					)}
				</div>
			</ErrorBoundary>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activeCareOrder: state.careOrder,
		activePatient: state.activePatient,
		availabilitySearchConfig: state.config.availabilitySearch,
		emailCancellationEnabled: state.config.notification.emailCancellationEnabled,
		decisionSupport: state.config.decisionSupport,
		decisionSupportOutput: state.decisionSupport,
		enableCancellationEmailOptIn: state.config.notification.showPatientCancellationEmailOptIn,
		notificationConfig: state.config.notification,
		patientEmail: state.activePatient.details.email,
		patientReferenceId: state.activePatient.details.referenceId,
		patientCancellationEmailDisclaimer: state.config.notification.patientCancellationEmailDisclaimer,
		previousAppointmentReferenceId: state.appointment.cancelRescheduleInfo.previousAppointmentReferenceId,
		providerFieldConfig: state.config.provider,
		referralSiteId: state.auth.referralSiteId,
		referralSystemId: state.auth.referralSystemId,
		cancelRescheduleInfo: state.appointment.cancelRescheduleInfo,
		schedulingConfig: state.config.scheduling,
		systemCancelReasons: state.config.systemCancelReasons,
		token: state.auth.token,
		useDecisionSupport: state.config.decisionSupport.useDecisionSupport,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			availabilitySearch: bindActionCreators(availabilitySearchActions, dispatch),
			appointment: bindActionCreators(appointmentActions, dispatch),
			rd2RefactorActions: bindActionCreators(rd2RefactorActions, dispatch),
		},
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentCancelView);
