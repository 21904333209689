import { useDispatch, useSelector } from 'react-redux';
import PatientDetails from './comps/patientDetails'
import PatientCareOrderHistory from './comps/patientCareOrderHistory';
import { useEffect, useState } from 'react';
import { onPatientDetailsViewMount } from 'actions/rd2RefactorActions';
import useLoadPatientDetails from './hooks/useLoadPatientDetails';
import useLoadPatientCareOrderHistory from './hooks/useLoadPatientCareOrderHistory';
import PatientAppointmentHistory from './comps/patientAppointmentHistory';
import useLoadPatientAppointmentHistory from './hooks/useLoadPatientAppointmentHistory';
import ActiveErrors from 'components/common/activeErrors';
import LoadingIndicator from 'components/loadingIndicator';

import './css/patientDetailsView.css';
import useLoadPatientOpenWorkflows from './hooks/useLoadPatientOpenWorkflows';
import PatientOpenWorkflows from './comps/patientOpenWorkflows';

export default function PatientDetailsView(props: any) {
    const dispatch = useDispatch();

    const patient = useSelector((state: any) => state.activePatient);
    const patientConfig = useSelector((state: any) => state.config.patient);
    const schedulingConfig = useSelector((state: any) => state.config.scheduling)
    const closeCareOrderEnabled = useSelector((state: any) => state.config.orderManagement.closeCareOrderEnabled);

    const [isPatientLoading, setIsPatientLoading] = useState(false);
    const [isPatientAppointmentHistoryLoading, setIsPatientAppointmentHistoryLoading] = useState(false);
    const [isPatientCareOrderHistoryLoading, setIsPatientCareOrderHistoryLoading] = useState(false);
    const [isPatientOpenWorkflowsLoading, setIsPatientOpenWorkflowsLoading] = useState(false);

    useEffect(() => {
        dispatch(onPatientDetailsViewMount());
    }, [dispatch])

    if (!props.match?.params?.referenceId) {
        throw new Error("Missing Patient Id");
    }

    const isPatientAlreadyActive = (patient?.id === props.match?.params?.referenceId);
    const isPatientAlreadyLoaded = isPatientAlreadyActive && (patient.details.firstName !== undefined);
    const shouldLoadPatient = !isPatientAlreadyLoaded;
    useLoadPatientDetails(
        props.match.params.referenceId,
        shouldLoadPatient,
        setIsPatientLoading
    );

    const shouldLoadPatientAppointmentHistory = patientConfig.isPatientHistoryEnabled;
    useLoadPatientAppointmentHistory(
        patient.id,
        shouldLoadPatientAppointmentHistory,
        setIsPatientAppointmentHistoryLoading
    );

    const shouldLoadPatientCareOrderHistory = patientConfig.isPatientCareOrderHistoryEnabled;
    useLoadPatientCareOrderHistory(
        patient.id,
        shouldLoadPatientCareOrderHistory,
        setIsPatientCareOrderHistoryLoading
    );

    const shouldLoadPatientOpenWorkflows = patientConfig.isDecisionSupportWorkflowHistoryEnabled;
    useLoadPatientOpenWorkflows(
        patient.id,
        shouldLoadPatientOpenWorkflows,
        setIsPatientOpenWorkflowsLoading,
        schedulingConfig.bookOpenDecisionSupportWorkflowMaxAgeInDays,
    )

    return (
        <div>
            <ActiveErrors />
            {isPatientLoading &&
                <LoadingIndicator />
            }
            {!isPatientLoading &&
                <>
                    <PatientDetails
                        config={patientConfig}
                        patient={patient}
                    />
                    {(isPatientAppointmentHistoryLoading || isPatientCareOrderHistoryLoading || isPatientOpenWorkflowsLoading) &&
                        <div><LoadingIndicator /></div>
                    }
                    {(!isPatientAppointmentHistoryLoading && !isPatientCareOrderHistoryLoading && !isPatientOpenWorkflowsLoading) &&
                        <>
                            {patientConfig.isPatientCareOrderHistoryEnabled &&
                                <PatientCareOrderHistory
                                    showHeading={true}
                                    showBookButton={true}
                                    showCloseButton={closeCareOrderEnabled}
                                    showFilters={true}
                                    setIsPatientCareOrderHistoryLoading={setIsPatientCareOrderHistoryLoading}
                                />
                            }
                            {patientConfig.isPatientHistoryEnabled &&
                                <PatientAppointmentHistory
                                    showHeading={true}
                                    showLoseProgressWarning={false}
                                    enableShowPSAandMHDfilters={true}
                                />
                            }
                            {patientConfig.isDecisionSupportWorkflowHistoryEnabled &&
                                <PatientOpenWorkflows
                                    showHeading={true}
                                    showLoseProgressWarning={false}
                                    showBookButton={schedulingConfig.enableBookOpenDecisionSupportWorkflow}
                                />
                            }
                        </>
                    }
                </>
            }
        </div>
    )
}
