import React from 'react';
import PropTypes from 'prop-types';

import {
	ButtonToolbar,
	Col,
	Form,
	Row,
	Label,
	Button,
} from 'reactstrap';

import './appointmentEditForm.css';

export default function AppointmentEditForm(props) {
	const {
		config,
		details,
		isSaving,
		onChange,
		onCancel,
		onSave,
		providerResponse,
		reasonForVisit,
	} = props;

	let reasonForVisitLabel = config.reasonForVisit.fieldLabel ? config.reasonForVisit.fieldLabel : "Reason For Visit";
	let providerResponseLabel = "Provider Response";
	let providerResponseCharacterLimit = 500;

	let isSaveDisabled = ((!details.reasonForVisit && !reasonForVisit) || (details.reasonForVisit && details.reasonForVisit.trim() === reasonForVisit.trim())) && ((!details.providerResponse && !providerResponse) || (details.providerResponse && details.providerResponse.trim() === providerResponse.trim()));

	return (
		<Form>
			<div className='mt-4'>
				<Row>
					<Col lg="4">
						<div className="form-group">
							<Label className='fw-bold'>{reasonForVisitLabel.toUpperCase()}</Label>
							<textarea maxLength={config.reasonForVisitCharacterLimit} className="form-control" id="reasonForVisit" name="reasonForVisit" rows="7" value={reasonForVisit} onChange={onChange} />
						</div>
					</Col>
					{config.showProviderResponseApptDetails &&
						<Col lg="4">
							<div className="form-group">
								<Label className='fw-bold'>{providerResponseLabel.toUpperCase()}</Label>
								<textarea maxLength={providerResponseCharacterLimit} className="form-control" id="providerResponse" name="providerResponse" rows="7" value={providerResponse} onChange={onChange} />
							</div>
						</Col>
					}
				</Row>
			</div>
			<Row className="action-buttons">
				<ButtonToolbar>
					<Button color="primary" disabled={isSaveDisabled} onClick={onSave}>Save</Button>
					<Button className='ml-2' color='secondary' onClick={onCancel} disabled={isSaving}>Cancel</Button>
				</ButtonToolbar>
			</Row>
		</Form>
	)
}

AppointmentEditForm.propTypes = {
	config: PropTypes.object,
	details: PropTypes.object,
	isSaving: PropTypes.bool,
	onChange: PropTypes.func,
	onCancel: PropTypes.func,
	onSave: PropTypes.func,
	providerResponse: PropTypes.string,
	reasonForVisit: PropTypes.string,
}
