import * as types from '../actions/actionTypes'
import moment from 'moment';

moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';

const initialState = {
	id: null,
	details: {},
	history: null,
	careOrderHistory: null,
	openWorkflows: null,
	schedulableOrders: [],
};

export default function reducer(state = initialState, action) {
	let patientDetails;
	switch (action.type) {
		case types.TOKEN_LINK_PATIENT_REFID_LOOKUP:
			return state;
		case types.SET_ACTIVE_PATIENT:
			if (action.referenceId === state.id) {
				return state;
			}
			else {
				return {
					...initialState,
					id: action.referenceId,
				}
			}
		case types.SET_ACTIVE_PATIENT_DETAILS_SUCCESS:
			if (action.payload.data.referenceId === state.id) {
				patientDetails = action.payload.data;
				patientDetails.dateOfBirth = moment.utc(action.payload.data.dateOfBirth).format("MM/DD/YYYY");
				return {
					...state,
					details: patientDetails,
				}
			}
			else {
				return state;
			}
		case types.PATIENT_HISTORY_SUCCESS:
			if (action.meta?.previousAction?.patientRef !== state.id)
				return state;

			return {
				...state,
				history: action.payload.data
			}
		case types.PATIENT_CARE_ORDER_HISTORY_SUCCESS:
			if (action.meta?.previousAction?.patientRef !== state.id)
				return state;

			return {
				...state,
				careOrderHistory: action.payload.data
			}
		case types.PATIENT_OPEN_WORKFLOWS_SUCCESS:
			if (action.meta?.previousAction?.patientRef !== state.id)
				return state;

			return {
				...state,
				openWorkflows: action.payload.data
			}
		case types.CLEAR_ACTIVE_PATIENT:
			return initialState;
		case types.GET_PATIENT_ID_BY_IDENTITY_SUCCESS:
			patientDetails = action.payload.data.patient;
			patientDetails.dateOfBirth = moment.utc(action.payload.data.patient.dateOfBirth).format("MM/DD/YYYY");
			return {
				...state,
				id: action.payload.data.patient.referenceId,
				details: patientDetails,
			};
		case types.GET_PATIENT_SCHEDULABLE_ORDERS_SUCCESS:
			return {
				...state,
				schedulableOrders: action.payload.data.orders,
			}
		case types.APPOINTMENT_BOOK_SUCCESS:
		case types.APPOINTMENT_CANCEL_SUCCESS:
			return {
				...state,
				history: null,
				careOrderHistory: null,
			}
		default:
			return state
	}
}
