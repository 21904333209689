import { useSelector } from "react-redux";
import PatientOpenWorkflowsGrid from "./patientOpenWorkflowsGrid";

const PatientOpenworkflows = (
    {
        showLoseProgressWarning,
        showHeading,
        showBookButton,
    }: {
        showLoseProgressWarning: boolean,
        showHeading: boolean,
        showBookButton: boolean,
    }
) => {
    const openWorkflows = useSelector((state: any) => state.activePatient.openWorkflows);

    return (
        <div>
            {showHeading && <PatientOpenWorkflowsHeader />}
            <div>
                {openWorkflows?.length > 0 &&
                    <div className="col" style={{ padding: '0px' }}>
                        <PatientOpenWorkflowsGrid
                            openWorkflows={openWorkflows}
                            showLoseProgressWarning={showLoseProgressWarning}
                            showBookButton={showBookButton}
                        />
                    </div>
                }
                {openWorkflows && (openWorkflows.length === 0) &&
                    <PatientOpenWorkflowsEmptyList />
                }
            </div>
        </div>
    )
}

function PatientOpenWorkflowsEmptyList() {
    return (
        <div>
            <h5 className="history-result-header">No history records found.</h5>
        </div>
    );
}

const PatientOpenWorkflowsHeader = () => {
    return (
        <h4 className="title">Open Workflows</h4>
    )
}


export default PatientOpenworkflows;