import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import config from 'config';
import { PasswordInput } from '../input';
import { PasswordConfigInfo } from 'types';

interface ResetPasswordProps {
	email: string;
	passwordConfig: PasswordConfigInfo;
	onSubmit: (newPassword: string, confirmPassword: string) => void;
	onCancel: () => void;
}

interface ResetPasswordState {
	newPassword: string;
	confirmPassword: string;
	isNotLongEnough: boolean;
	isNotConfirmed: boolean;
}

export const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
	const { email, passwordConfig, onSubmit, onCancel } = props;

	const [state, setState] = useState<ResetPasswordState>({
		newPassword: '',
		confirmPassword: '',
		isNotLongEnough: false,
		isNotConfirmed: false,
	});

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setState((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const showCurrentYear = () => {
		return new Date().getFullYear();
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (validatePasswords()) {
			onSubmit(state.newPassword, state.confirmPassword);
		}
	};

	const validatePasswords = () => {
		const isNewValid = validateField('newPassword', state.newPassword);
		const isConfirmedValid = validateField('confirmPassword', state.confirmPassword);
		return isNewValid && isConfirmedValid;
	};

	const validateField = (field: string, value: string) => {
		switch (field) {
			case 'newPassword':
				const isNotLongEnough = !value || value.length < passwordConfig.requiredLength;
				setState((prevState) => ({ ...prevState, isNotLongEnough }));
				return !isNotLongEnough;

			case 'confirmPassword':
				const isNotConfirmed = state.newPassword !== state.confirmPassword;
				setState((prevState) => ({ ...prevState, isNotConfirmed }));
				return !isNotConfirmed;

			default:
				return false;
		}
	};

	const cancel = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		onCancel();
	};

	return (
		<div>
			<Form onSubmit={handleSubmit}>
				<div>
					<div className="font-weight-bold form-label">User Email:</div>
					<div className="form-label">{email}</div>
				</div>
				<FormGroup>
					<Label for="newPassword" className="font-weight-bold form-label">
						New Password:
					</Label>
					<PasswordInput name="newPassword" id="newPassword" onChange={handleChange} value={state.newPassword} />
					{state.isNotLongEnough && (
						<div className="validation-error">
							Your new password must be at least {passwordConfig.requiredLength} characters.
						</div>
					)}
				</FormGroup>
				<FormGroup>
					<Label for="confirmPassword" className="font-weight-bold form-label">
						Confirm Password:
					</Label>
					<PasswordInput
						name="confirmPassword"
						id="confirmPassword"
						onChange={handleChange}
						value={state.confirmPassword}
					/>
					{state.isNotConfirmed && <div className="validation-error">You must confirm your new password.</div>}
				</FormGroup>
				<FormGroup>
					<Button color="success" type="submit" block>
						Save Password
					</Button>
					<Button color="link" onClick={cancel} block>
						Cancel
					</Button>
				</FormGroup>
				<div style={{ fontSize: '15px', marginTop: '-10px' }} className="text-center forgotPasswordFooterSize">
					As a HITRUST Certified company, we are committed to HIPAA compliance and the privacy and integrity of all
					patients and their data. All passwords must contain at least {passwordConfig.requiredLength} characters,
					{passwordConfig.requireUppercase && ' 1 capital letter,'}
					{passwordConfig.requireLowercase && ' 1 lower case letter,'}
					{passwordConfig.requireDigit && ' 1 number,'}
					{passwordConfig.requireNonAlphanumeric && ' 1 special character,'}
					&nbsp;and new passwords cannot be one of your last {passwordConfig.passwordHistoryCount} passwords.
				</div>
				<div className="text-center forgotPasswordFooterSize">
					@ {showCurrentYear()} MyHealthDirect, Inc. All Rights Reserved.
				</div>
				<div className="text-center forgotPasswordContactUs">
					Need Help? Please contact us at (629) 777-5552 | {config.version}
				</div>
			</Form>
		</div>
	);
};

export default ResetPassword;
