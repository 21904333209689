import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { flushAvailabilityAndScanResults, searchCalendarsThunk } from "features/searchAsync/availabilitySlice";
import { processDecisionSupportSubpoints } from "actions/decisionSupportActions";
import { startAvailabilitySearch } from "actions/rd2RefactorActions";
import {
	Button,
	ButtonToolbar,
	Col,
	FormGroup,
	Input,
	Label,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';

import {
	EmailInput,
	ZipCodeInput,
	DateInput,
	PhoneNumberInput,
} from '../input/index';

import {
	validateEmail,
	validatePhoneNumber,
	validateZipCode
} from '../../lib/validation';

import LoadingIndicator from '../../components/loadingIndicator';

import { valueDisplay, valueDisplayBottomMargin } from '../../lib/standardLibrary';
import AsyncSelect from 'react-select/async';
import { isUndefined } from 'is-what';

function skipValidation(props) {
	return (!props.shouldValidate || !props.shouldValidate(props.id));
}

/* WIP - TextInputWithLabelAndCol - props { lgSize, ... } */
export function TextInputWithLabelAndCol(props) {
	let lgSize = props.lgSize || 4;
	return (
		<Col lg={lgSize}>
			<TextInputWithLabel {...props} />
		</Col>
	);
}

export function TextAreaWithLabel(props) {
	let isValid = validateHelper(props);
	return (
		<FormGroup>
			<Label className="fw-bold" for={props.id}>{props.label.toUpperCase()}</Label>
			<Input style={{ resize: "none", height: "150px", maxWidth: "100%" }} type="textarea" name={props.id} id={props.id} maxLength={props.maxLength} placeholder={props.label}
				invalid={!isValid} onChange={props.onChange} value={props.value} disabled={props.disabled} />
			{/* NOTE: We have repeated the 2 conditions below in order to avoid having to inject a wrapper element (for jsx) */}
			{props.isRequired &&
				<small id={props.id + "Required"} className="form-text text-muted">Required</small>
			}
			{props.isRequired &&
				<div className="invalid-feedback">
					Please provide a valid {props.label}.
				</div>
			}
		</FormGroup>
	)
}

export function DisplayCustomValueResponses(props) {
	const { label, value } = props;
	return (
		<p><span className='fw-bold'>{label}:</span> {value}</p>
	)
}

export function AddressDisplay(props) {
	const { label, address } = props;
	let isEmpty = address.addressLine1 ? false : true;
	let defaultValue = props.defaultValue || '-';
	let addressDisplayString = isEmpty ? defaultValue
		: `${address.addressLine1} ${address.addressLine2 ? address.addressLine2 : ''} ${address.cityName}, ${address.stateCode} ${address.zipCode}`;
	return (
		<LabeledField label={label} value={addressDisplayString} />
	);
}

export function validateIfRequired(value, isRequired, skipValidation = false) {
	let isEmpty = typeof value === "string" ? !(value.trim()) : !value;
	let invalid = !skipValidation && isEmpty && isRequired;
	return !invalid;
}

export function validateWithFunc(value, isRequired, validateContentFunc, skipValidation = false) {
	let isEmpty = !value;
	let invalid = !skipValidation && ((isEmpty && isRequired) || (!isEmpty && !validateContentFunc(value)));
	return !invalid;
}

export function validateHelper(props, validateContentFunc) {
	let skip = skipValidation(props);
	if (validateContentFunc) {
		return validateWithFunc(props.value, props.isRequired, validateContentFunc, skip);
	}
	else {
		return validateIfRequired(props.value, props.isRequired, skip);
	}
}

export function validateDate(value) {
	let dateUtc = moment.utc(value);
	return dateUtc.isValid();
}

/* SectionHeading - props { label*, style } */
export function SectionHeading(props) {
	let style = props.style || { "marginBottom": "35px" };
	return (
		<h4 style={style}>{props.label}</h4>
	);
}

/* SaveCancelActionBar - props { style, onSave*, onCancel* } */
export function SaveCancelActionBar(props) {
	let style = props.style || { marginTop: '20px' };
	let { saveButtonText, cancelButtonText } = props;
	return (
		<Row style={style}>
			<Col className='actionBar'>
				<ButtonToolbar>
					<SaveButton {...props} buttonText={saveButtonText} onClick={props.onSave} />
					<CancelButton buttonText={cancelButtonText} onClick={props.onCancel} />
				</ButtonToolbar>
			</Col>
		</Row>
	);
}

/* CancelButton - props { isDisabled, buttonText, onClick* } */
export function CancelButton(props) {
	let buttonText = props.buttonText || 'Cancel';
	let style = props.style || { marginLeft: '5px' };
	return (
		<Button style={style} disabled={props.isDisabled} onClick={props.onClick}><span>{buttonText}</span></Button>
	);
}

/* SaveButton - props { isDisabled, isBusy, busyText, buttonText, onClick* } */
export function SaveButton(props) {
	let isDisabled = props.isDisabled || props.isBusy;
	let busyText = props.busyText || ' Saving...';
	let normalText = props.buttonText || 'Save';
	return (
		<Button type="submit" color="primary" disabled={isDisabled} onClick={props.onClick}>
			{props.isBusy
				? <span><FontAwesomeIcon icon="spinner" spin />{busyText}</span>
				: <span>{normalText}</span>
			}
		</Button>
	);
}

/* TextInputWithLabel - props { id*, label*, maxLength, isRequired, value } */
export function TextInputWithLabel(props) {
	let subText = props.subText || 'Required';
	let invalidMessageText = props.invalidMessageText || ('Please provide a valid ' + props.label + '.');
	let isValid = validateHelper(props);
	return (
		<FormGroup>
			<Label className="fw-bold" for={props.id}>{props.label.toUpperCase()}</Label>
			<Input type="text" name={props.id} id={props.id} maxLength={props.maxLength} placeholder={props.label}
				invalid={!isValid} onChange={props.onChange} value={props.value || ''} disabled={props.disabled} />
			{/* NOTE: We have repeated the 2 conditions below in order to avoid having to inject a wrapper element (for jsx) */}
			{props.isRequired &&
				<small id={props.id + "Required"} className="form-text text-muted">{subText}</small>
			}
			{props.isRequired &&
				<div className="invalid-feedback">
					{invalidMessageText}
				</div>
			}
		</FormGroup>
	);
}

export function ZipInputWithLabel(props) {
	let subText = props.subText || 'Required';
	let invalidMessageText = props.invalidMessageText || ('Please provide a valid ' + props.label + '.');
	let isValid = !props.forceInvalid && validateHelper(props, validateZipCode);
	let className = 'form-control' + ((!isValid) ? ' is-invalid' : '');
	return (
		<FormGroup>
			<Label className="fw-bold" for={props.id}>{props.label.toUpperCase()}</Label>
			<ZipCodeInput className={className} name={props.id} id={props.id} placeholder={props.label} onChange={props.onChange} value={props.value} disabled={props.disabled} />
			{props.isRequired &&
				<small id={props.id + "Required"} className="form-text text-muted">{subText}</small>
			}
			{props.isRequired &&
				<div className="invalid-feedback">{invalidMessageText}</div>
			}
		</FormGroup>
	);
}

export function ZipInputWithoutLabel(props) {
	let invalidMessageText = props.invalidMessageText || ('Please provide a valid ' + props.label + '.');
	let isValid = !props.forceInvalid && validateHelper(props, validateZipCode);
	let className = 'form-control' + ((!isValid) ? ' is-invalid' : '');
	return (
		<FormGroup>
			<ZipCodeInput className={className} name={props.id} id={props.id} placeholder={props.label} onChange={props.onChange} value={props.value} disabled={props.disabled} />
			{props.isRequired &&
				<div className="invalid-feedback">{invalidMessageText}</div>
			}
		</FormGroup>
	);
}

export function EmailInputWithLabel(props) {
	let subText = props.subText || 'Required';
	let invalidMessageText = props.invalidMessageText || ('Please provide a valid ' + props.label + '.');
	let isValid = validateHelper(props, validateEmail);
	let className = 'form-control' + ((!isValid) ? ' is-invalid' : '');
	return (
		<FormGroup>
			<Label className="fw-bold" for={props.id}>{props.label.toUpperCase()}</Label>
			<EmailInput className={className} name={props.id} id={props.id} placeholder={props.label} onChange={props.onChange} value={props.value} disabled={props.disabled} />
			{props.isRequired &&
				<small id={props.id + "Required"} className="form-text text-muted">{subText}</small>
			}
			{props.isRequired &&
				<div className="invalid-feedback">{invalidMessageText}</div>
			}
		</FormGroup>
	);
}

export function PhoneInputWithLabel(props) {
	let subText = props.subText || 'Required';
	let invalidMessageText = props.invalidMessageText || ('Please provide a valid ' + props.label + '.');
	let isValid = !props.forceInvalid && validateHelper(props, validatePhoneNumber);
	let className = 'form-control' + ((!isValid) ? ' is-invalid' : '');

	return (
		<FormGroup>
			<Label className="fw-bold" for={props.id}>{props.label.toUpperCase()}</Label>
			<PhoneNumberInput className={className} name={props.id} id={props.id} placeholder={props.label} onChange={props.onChange} value={props.value} disabled={props.disabled} />
			{(props.isRequired || subText !== 'Required') &&
				<small className="form-text text-muted">{subText}</small>
			}
			{props.isRequired &&
				<div className="invalid-feedback">{invalidMessageText}</div>
			}
			{props.forceInvalid &&
				<div className="invalid-feedback">{props.forcedInvalidMessage}</div>
			}
		</FormGroup>
	);
}

export function DropdownSelect(props) {
	let subText = props.subText || 'Required';
	let invalidMessageText = props.invalidMessageText || ('Please select a ' + props.label + '.');
	let isValid = validateHelper(props);
	let className = 'form-control' + ((!isValid) ? ' is-invalid' : '');
	return (
		<FormGroup>
			<Input type="select" className={className} name={props.name || props.id} id={props.id} placeholder={props.label} onChange={props.onChange} onBlur={props.onBlur} value={props.value} disabled={props.disabled}>
				{props.optionList.map((currentOption) => {
					return <option key={currentOption.id} value={currentOption.id}>{currentOption.name ? currentOption.name : currentOption.id}</option>
				})}
			</Input>
			{props.isRequired &&
				<small id={props.id + "Required"} className="form-text text-muted">{subText}</small>
			}
			{props.isRequired &&
				<div className="invalid-feedback">{invalidMessageText}</div>
			}
		</FormGroup>
	);
}

export function DropdownSelectWithLabel(props) {
	let subText = props.subText || 'Required';
	let invalidMessageText = props.invalidMessageText || ('Please select a ' + props.label + '.');
	let isValid = validateHelper(props);
	let className = 'form-control' + ((!isValid) ? ' is-invalid' : '');
	return (
		<FormGroup>
			<Label className="fw-bold" for={props.id}>{props.label.toUpperCase()}</Label>
			<Input type="select" className={className} name={props.name || props.id} id={props.id} placeholder={props.label} onChange={props.onChange} value={props.value} disabled={props.disabled}>
				{props.optionList.map((currentOption) => {
					return <option key={currentOption.id} value={currentOption.id}>{currentOption.name ? currentOption.name : currentOption.id}</option>
				})}
			</Input>
			{props.isRequired &&
				<small id={props.id + "Required"} className="form-text text-muted">{subText}</small>
			}
			{props.isRequired &&
				<div className="invalid-feedback">{invalidMessageText}</div>
			}
		</FormGroup>
	);
}

export function DateInputWithLabel(props) {
	let subText = props.subText || 'Required';
	let invalidMessageText = props.invalidMessageText || ('Please provide a valid ' + props.label + '.');
	let isValid = validateHelper(props, props.validator ? props.validator : validateDate);
	let className = 'form-control' + ((!isValid) ? ' is-invalid' : '');
	return (
		<FormGroup>
			<Label className="fw-bold" for={props.id}>{props.label.toUpperCase()}</Label>
			<DateInput invalid={!isValid} className={className} disabled={props.disabled} type="text" name={props.id} id={props.id} placeholder="mm/dd/yyyy" onChange={props.onChange} value={props.value} />
			{props.isRequired &&
				<small id={props.id + "Required"} className="form-text text-muted">{subText}</small>
			}
			{props.isRequired &&
				<div className="invalid-feedback">
					{invalidMessageText}
				</div>
			}
		</FormGroup>
	);
}

export function EntityEditButton(props) {
	const { to } = props;
	return (
		<Link to={to} style={{ color: 'black' }}><FontAwesomeIcon icon='user-edit' /></Link>
	);
}


export function LargeActionButton(props) {
	const { label, to } = props;
	return (
		<Link to={to}><Button color="primary" size="lg">{label}</Button></Link>
	);
}

export function LabeledField(props) {
	const { label, value } = props;
	let defaultValue = props.defaultValue || '-';
	let isHidden = props.isHidden || false;
	return (
		<div>
			{!isHidden &&
				<p className="fw-bold form-label">{label.toUpperCase()}</p>
			}
			{!isHidden &&
				<p>{value ? value : defaultValue}</p>
			}
		</div>
	);
}

export function LabeledFieldHorizontal(props) {
	const { label, value } = props;
	let defaultValue = props.defaultValue || '-';
	let isHidden = props.isHidden || false;
	return (
		<div>
			{!isHidden &&
				<div>
					<span className="fw-bold form-label">{label.toUpperCase()}:</span> <span>{value ? value : defaultValue}</span>
				</div>
			}
		</div>
	);
}

/* This format is for display only and does not match our DB format */
function formatPhone(value) {
	var cleanPhone = value.replace(/\D/g, '');
	var match = cleanPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	}
}

export function LabeledPhoneFieldHorizontal(props) {
	const { label, value } = props;
	let defaultValue = props.defaultValue || '-';
	let isHidden = props.isHidden || false;
	let formattedPhone = formatPhone(value);
	return (
		<div>
			{!isHidden &&
				<div>
					<span className="fw-bold form-label">{label.toUpperCase()}:</span> <span>{formattedPhone ? formattedPhone : defaultValue}</span>
				</div>
			}
		</div>
	);
}

export function RadioSelection2WithLabel(props) {
	let isValid = validateHelper(props);
	return (
		<FormGroup>
			<Label className="fw-bold" for={props.id}>{props.label.toUpperCase()}</Label>
			<div>
				<div className="btn-group" role="group">
					<RadioOption groupId={props.id} optionValue={props.option1Value} id={props.id + props.option1Value} label={props.option1Label} selectedValue={props.value} isValid={isValid} onChange={props.onChange} />
					<RadioOption groupId={props.id} optionValue={props.option2Value} id={props.id + props.option2Value} label={props.option2Label} selectedValue={props.value} isValid={isValid} onChange={props.onChange} />
				</div>
				<Row>
					<Col>
						<small id={props.id + "Required"} className="form-text text-muted">Required</small>
						<div className="invalid-feedback" style={{ display: !isValid ? 'block' : 'none' }}>Please select a {props.label}.</div>
					</Col>
				</Row>
			</div>
		</FormGroup>
	);
}

export function RadioOption(props) {
	let isSelected = (props.selectedValue === props.optionValue);
	let isValid = props.isValid === undefined || Boolean(props.isValid);
	let inputClassName = "custom-control-input" + ((!isValid) ? ' is-invalid' : '');
	return (
		<div className='custom-control custom-radio custom-control-inline form-check'>
			<Input type="radio" className={inputClassName} id={props.id} name={props.groupId} checked={isSelected} value={props.optionValue} onChange={props.onChange}></Input>
			<Label className="custom-control-label form-check-label" htmlFor={props.id} style={{ marginRight: "10px" }}>{props.label}</Label>
		</div>
	);
}

export function RadioSelection3WithLabel(props) {
	return (
		<div>
			<Label className="fw-bold" for={props.id}>{props.label.toUpperCase()}</Label>
			<div>
				<RadioOption groupId={props.id} value={props.option1Value} id={props.id + props.option1Value} label={props.option1Label} selectedValue={props.value} onChange={props.onChange} />
				<RadioOption groupId={props.id} value={props.option2Value} id={props.id + props.option2Value} label={props.option2Label} selectedValue={props.value} onChange={props.onChange} />
				<RadioOption groupId={props.id} value={props.option3Value} id={props.id + props.option3Value} label={props.option3Label} selectedValue={props.value} onChange={props.onChange} />
				<div>
					<small id={props.id + "Required"} className="form-text text-muted">Required</small>
					<div className="invalid-feedback" style={{ display: !props.isValid ? 'block' : 'none' }}>Please select a {props.label}.</div>
				</div>
			</div>
		</div>
	);
}

export function CheckboxLabelLeft(props) {
	let {
		id,
		label,
		onChange,
		checked
	} = props;
	return (
		<FormGroup>
			<Label className="fw-bold form-check-label" for={id}>
				<Input type="checkbox" className="custom-checkbox-left" id={id} name={id} onChange={onChange} checked={checked} />
				{label.toUpperCase()}
			</Label>
		</FormGroup>
	)
}

/* ConfirmModal is a modal that shows 1 button for the user to confirm/acknowledge */
export function ConfirmationModal(props) {
	const { className, title, body, buttonLabel, isOpen, onConfirm, onCancel } = props;
	return (
		<Modal className={className} centered isOpen={isOpen} toggle={onCancel}>
			<ModalHeader toggle={onCancel}>{title}</ModalHeader>
			<ModalBody>{body}</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={onConfirm}>{buttonLabel}</Button>
			</ModalFooter>
		</Modal>
	);
}

/* AlertModal is a specialized ConfirmModal where Confirm and Cancel perform the same action (onClose) */
export function AlertModal(props) {
	const { className, title, body, buttonLabel, isOpen, onClose } = props;
	let myButtonLabel = buttonLabel || 'OK';
	return (
		<ConfirmationModal className={className} title={title} body={body} isOpen={isOpen} buttonLabel={myButtonLabel} onConfirm={onClose} onCancel={onClose} />
	);
}

/* ConfirmModal is a modal that shows 2 buttons for the user to either confirm or cancel an action */
export function ConfirmOrCancelModal(props) {
	const { className, title, body, isOpen, confirmLabel, cancelLabel, onConfirm, onCancel } = props;
	let myConfirmLabel = confirmLabel || 'OK';
	let myCancelLabel = cancelLabel || 'Cancel';
	return (
		<Modal className={className} centered isOpen={isOpen} toggle={onCancel}>
			<ModalHeader toggle={onCancel}>{title}</ModalHeader>
			<ModalBody>{body}</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={onConfirm}>{myConfirmLabel}</Button>{' '}
				<Button color="secondary" onClick={onCancel}>{myCancelLabel}</Button>
			</ModalFooter>
		</Modal>
	);
}

/* YesNoModal is a specialized ConfirmOrCancelModal where Yes=Confirm and No=Cancel */
export function YesNoModal(props) {
	const { className, title, body, isOpen, yesLabel, noLabel, onYes, onNo } = props;
	let myYesLabel = yesLabel || 'Yes';
	let myNoLabel = noLabel || 'No';
	return (
		<ConfirmOrCancelModal className={className} title={title} body={body} confirmLabel={myYesLabel} cancelLabel={myNoLabel} isOpen={isOpen} onConfirm={onYes} onCancel={onNo} />
	);
}

export function CancelAppointmentModal(props) {
	const title = props.title || 'Cancel Appointment';
	const body = props.body || 'Are you sure you would like to cancel this appointment?';
	return (
		<YesNoModal title={title} body={body} isOpen={props.isOpen} onYes={props.onYes} onNo={props.onNo} />
	);
}

export function BookAnotherAppointmentModal(props) {
	const {
		className,
		config,
		decisionSupportSessionSubpoints,
		deepCopy,
		dispatch,
		isOpen,
		isLoading,
		hasAgedOut,
		historyRecordPendingAction,
		location,
		setShowBookAnotherOptionsModal,
		setIsHandlingBookAnother,
		setHistoryRecordPendingAction
	} = props;

	const title = 'Book Another Appointment';
	const body = 'Do you want to book another appointment like this one?'

	const onBookAnotherLikeThis = (e) => {
		e.preventDefault();
		setIsHandlingBookAnother(true);

		if (decisionSupportSessionSubpoints) {
			dispatch(async (dispatch, getState) => {
				await dispatch(processDecisionSupportSubpoints(decisionSupportSessionSubpoints, true));
				const state = getState();
				await dispatch(
					startAvailabilitySearch(
						state.careOrder,
						state.decisionSupport,
						state.config.availabilitySearch,
						state.activePatient.details,
					),
				);
				if (location.pathname === "/availability/search") {
					dispatch(flushAvailabilityAndScanResults())
					dispatch(searchCalendarsThunk())
				}
			});

			setIsHandlingBookAnother(false);
			setShowBookAnotherOptionsModal(false);

			if (!isUndefined(setHistoryRecordPendingAction)) {
				setHistoryRecordPendingAction(null);
			}
		}
	}

	const onBookAnotherLikeThisWithSameProvider = (e) => {
		e.preventDefault();
		setIsHandlingBookAnother(true);

		if (decisionSupportSessionSubpoints) {
			const decisionSupportSessionSubpointsCopy = deepCopy(decisionSupportSessionSubpoints);

			let providerSubpoint = decisionSupportSessionSubpointsCopy.find((e) => e.key === 'selectedprovidernpi')
			if (providerSubpoint) {
				providerSubpoint.value = historyRecordPendingAction?.serviceNpi;
			} else {
				decisionSupportSessionSubpointsCopy.push({ key: 'selectedprovidernpi', value: historyRecordPendingAction?.serviceNpi })
			}

			dispatch(async (dispatch, getState) => {
				await dispatch(processDecisionSupportSubpoints(decisionSupportSessionSubpointsCopy, true));
				const state = getState();
				await dispatch(
					startAvailabilitySearch(
						state.careOrder,
						state.decisionSupport,
						state.config.availabilitySearch,
						state.activePatient.details,
					),
				);
				if (location.pathname === "/availability/search") {
					dispatch(flushAvailabilityAndScanResults())
					dispatch(searchCalendarsThunk())
				}
			});

			setIsHandlingBookAnother(false);
			setShowBookAnotherOptionsModal(false);

			if (!isUndefined(setHistoryRecordPendingAction)) {
				setHistoryRecordPendingAction(null);
			}
		}
	}

	const onCancel = (e) => {
		e.preventDefault();
		setShowBookAnotherOptionsModal(false);

		if (!isUndefined(setHistoryRecordPendingAction)) {
			setHistoryRecordPendingAction(null);
		}
	}

	return (
		<Modal className={className} centered isOpen={isOpen} toggle={onCancel}>
			<ModalHeader toggle={onCancel}>{title}</ModalHeader>
			<ModalBody>{body}</ModalBody>
			<ModalFooter>
				{isLoading ? (
					<LoadingIndicator />
				) : (
					<Col>
						{hasAgedOut &&
							<Row className='my-2 alert alert-danger'>
								<span>You cannot book another appointment like this because the decision support flow for this appointment was completed more than {config.bookAnotherWithSameDecisionSupportMaxAgeInDays} days ago!</span>
							</Row>
						}
						{config.enableBookAnotherWithSameDecisionSupport &&
							<Row className='my-2'>
								<Button color="primary" onClick={onBookAnotherLikeThis} disabled={hasAgedOut}>Book Another Appointment Like This</Button>
							</Row>
						}
						{config.enableBookAnotherWithSameDecisionSupportAndProvider &&
							<Row className='my-2'>
								<Button color="primary" onClick={onBookAnotherLikeThisWithSameProvider} disabled={hasAgedOut}>Book Another Appointment Like This With Same Provider</Button>
							</Row>
						}
						<Row className='my-2'>
							<Button color="secondary" onClick={onCancel}>Cancel</Button>
						</Row>
					</Col>
				)}
			</ModalFooter>
		</Modal>
	);
}

export function PreviousAppointmentDetailsModal(props) {
	const { onCancel, previousAppointmentModal, details, showProviderResponseApptDetails, showReasonForVisitApptDetails, providerFullNameProfessionalDesignation, providerConfig, showInsuranceOnAppointmentDetails } = props;

	const title = 'Old Appointment Information';
	let showArrivalTime = Boolean(details.expectedArrivalTime && details.expectedArrivalTime !== details.providerAppointmentDateTime);
	let showMappedAppointmentType = Boolean(details.mappedAppointmentTypeName && details.mappedAppointmentTypeName !== details.appointmentTypeName);

	return (
		<Modal size='lg' isOpen={previousAppointmentModal} toggle={onCancel}>
			<ModalHeader toggle={onCancel}>{title}</ModalHeader>
			<ModalBody>
				<Col lg="9">
					<div style={{ background: '#FAFAFA', border: "solid 1px #E0E0E0", padding: "10px", height: '100%', borderRadius: "5px" }}>
						<Row>
							<Col lg="4">
								<div>
									<p className="fw-bold form-label">WHEN</p>
									<p style={valueDisplay}>{details.providerAppointmentDateTime ? moment(details.providerAppointmentDateTime).format("dddd, MM/DD/YYYY") : '-'}</p>
									<p style={valueDisplay}>{details.patientAppointmentDateTime ? moment(details.patientAppointmentDateTime).format("hh:mm A") + ' ' + (details.patientAppointmentTimeZone ? details.patientAppointmentTimeZone : '') : '-'}{' (patient time)'}</p>
									<p style={valueDisplay}>{details.providerAppointmentDateTime ? moment(details.providerAppointmentDateTime).format("hh:mm A") + ' ' + (details.providerAppointmentTimeZone ? details.providerAppointmentTimeZone : '') : '-'}{' (provider time)'}</p>
									{showArrivalTime && <p style={valueDisplay}>{details.expectedArrivalTime ? moment(details.expectedArrivalTime).format("hh:mm A") + ' ' + (details.providerAppointmentTimeZone ? details.providerAppointmentTimeZone : '') : '-'}{' (arrival time)'}</p>}
								</div>
							</Col>
							<Col lg="8">
								<div>
									<p className="fw-bold form-label">WHERE</p>
									<p style={valueDisplay}>
										{(details.providerFirstName || details.providerLastName) ?
											providerFullNameProfessionalDesignation
											: '-'}
									</p>
									<p style={valueDisplay}>{details.providerSpecialty ? details.providerSpecialty : '-'}</p>
									<p style={valueDisplay}>
										{details.providerSiteName ? details.providerSiteName : '-'}
										{details.providerSiteName && details.providerSystemName ? ', ' : ''}
										{details.providerSystemName ? details.providerSystemName : ''}
									</p>
									{!details.suppressPhoneNumber && providerConfig.phone && providerConfig.phone.isVisible &&
										<p style={valueDisplay}>{providerConfig.phone.fieldLabel ? providerConfig.phone.fieldLabel : '(O)'}{details.providerPhoneNumber && details.providerPhoneNumber.trim() !== '' ? ` ${details.providerPhoneNumber}` : ' -'}</p>
									}
									{providerConfig.fax && providerConfig.fax.isVisible &&
										<p style={valueDisplayBottomMargin}>{providerConfig.fax.fieldLabel ? providerConfig.fax.fieldLabel : '(F)'}{details.providerFaxNumber && details.providerFaxNumber.trim() !== '' ? ` ${details.providerFaxNumber}` : ' -'}</p>
									}
									{!details.suppressAddress && details.providerAddressLine1 ?
										<div>
											<p style={valueDisplay}>{details.providerAddressLine1}</p>
											{details.providerAddressLine2 ? <p style={valueDisplay}>{details.providerAddressLine2}</p> : ''}
											<p>{details.providerCity}{', '}{details.providerState}{' '}{details.providerZip}</p>
										</div>
										: <div></div>}
								</div>
							</Col>
						</Row>
						<Row>
							<Col lg="4">
								<div>
									<p className="fw-bold form-label">TYPE</p>
									<p style={valueDisplayBottomMargin}>{details.appointmentTypeName ? details.appointmentTypeName : '-'}</p>
								</div>
								{showMappedAppointmentType &&
									<div className="mt-4">
										<p className="fw-bold form-label">MAPPED APPOINTMENT TYPE</p>
										<p style={valueDisplayBottomMargin}>{details.mappedAppointmentTypeName}</p>
									</div>
								}
							</Col>
							<Col lg="8">
								{showInsuranceOnAppointmentDetails && details.payorType ?
									<div>
										<p className="fw-bold form-label">INSURANCE</p>
										{details.payorType ?
											<div>
												<p style={valueDisplay}>{details.payorType}</p>
											</div>
											: <div></div>}
										{details.insuranceCarrierName ?
											<div>
												<p style={valueDisplay}>{details.insuranceCarrierName}</p>
											</div>
											: <div></div>}
									</div>
									:
									<div>
										<p>&nbsp;</p>
										<p style={valueDisplayBottomMargin}>&nbsp;</p>
									</div>
								}
							</Col>
						</Row>
						<Row>
							<Col lg="4">
								{showReasonForVisitApptDetails ?
									<div>
										<p className="fw-bold form-label">REASON FOR VISIT</p>
										<p style={valueDisplayBottomMargin}>{details.reasonForVisit ? details.reasonForVisit : '-'}</p>
									</div>
									:
									<div>
										<p>&nbsp;</p>
										<p style={valueDisplayBottomMargin}>&nbsp;</p>
									</div>
								}
							</Col>
							<Col lg="8">
								{showProviderResponseApptDetails ?
									<div>
										<p className="fw-bold form-label">PROVIDER RESPONSE</p>
										<p style={valueDisplayBottomMargin}>{details.providerResponse ? details.providerResponse : '-'}</p>
									</div>
									:
									<div>
										<p>&nbsp;</p>
										<p style={valueDisplayBottomMargin}>&nbsp;</p>
									</div>
								}
							</Col>
						</Row>
					</div>
				</Col>
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={() => { onCancel() }}>Close</Button>{' '}
			</ModalFooter>
		</Modal>
	)
}

export function AppointmentAlreadyPastModal(props) {
	const title = props.title || 'Cannot Cancel Appointment';
	const body = props.body || "The appointment's start time has passed.";
	return (
		<AlertModal title={title} body={body} isOpen={props.isOpen} onClose={props.onClose} />
	);
}

export function AddressSelectWithLabel(props) {
	let subText = props.subText || 'Required';
	let invalidMessageText = props.invalidMessageText || ('Please provide a valid ' + props.label + '.');
	let isValid = validateHelper(props);
	return (
		<FormGroup>
			<Label className="fw-bold" for={props.id}>{props.label.toUpperCase()}</Label>
			<div invalid={!isValid}>
				<AsyncSelect
					name={props.id}
					id={props.id}
					cacheOptions
					defaultOptions
					loadOptions={props.loadOptions}
					onChange={props.onChange}
					value={props.value}
					isDisabled={props.isDisabled}
					style={props.style}
					className={props.className}
					onKeyDown={props.onKeyDown}
				/>
			</div>

			{props.isRequired &&
				<small id={props.id + "Required"} className="form-text text-muted">{subText}</small>
			}
			{props.isRequired &&
				<div className="invalid-feedback">
					{invalidMessageText}
				</div>
			}
		</FormGroup>
	);
}