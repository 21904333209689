import { useState } from "react";
import { useSelector } from "react-redux";
import PatientAppointmentHistoryFilter from "./patientAppointmentHistoryFilter";
import PatientAppointmentHistoryGrid from "./patientAppointmentHistoryGrid";

import '../css/patientHistory.css';

export interface AppointmentHistoryFilters {
    hideMHD: boolean,
    hidePSA: boolean,
    includeCanceled: boolean,
}

const PatientAppointmentHistory = (
    {
        showHeading,
        showLoseProgressWarning,
        enableShowPSAandMHDfilters,
    }: {
        showHeading: boolean,
        showLoseProgressWarning: boolean,
        enableShowPSAandMHDfilters: boolean
    }
) => {
    const appointmentHistoryRecords = useSelector((state: any) => state.activePatient.history);
    const [filters, setFilters] = useState<AppointmentHistoryFilters>(
        {
            hideMHD: false,
            hidePSA: false,
            includeCanceled: false
        }
    )

    //TODO better name
    const showPSAandMHDfilters = enableShowPSAandMHDfilters
        && appointmentHistoryRecords?.some((x: any) => x.scheduleMethod === "PSA")
        && appointmentHistoryRecords?.some((x: any) => x.scheduleMethod === "MHD");

    return (
        <div>
            {showHeading && <PatientAppointmentHistoryHeader />}
            <div>
                {appointmentHistoryRecords?.length > 0 &&
                    <div className="col" style={{ padding: '0px' }}>
                        <PatientAppointmentHistoryFilter
                            filters={filters}
                            setFilters={setFilters}
                            showPSAandMHDfilters={showPSAandMHDfilters}
                        />
                        <PatientAppointmentHistoryGrid
                            appointmentHistoryRecords={appointmentHistoryRecords}
                            filters={filters}
                            showLoseProgressWarning={showLoseProgressWarning}
                        />
                    </div>
                }
                {appointmentHistoryRecords && (appointmentHistoryRecords.length === 0) &&
                    <PatientHistoryEmptyList />
                }
            </div>
        </div>
    )
}

const PatientAppointmentHistoryHeader = () => {
    return (
        <h4 className="title">Appointment History</h4>
    )
}

function PatientHistoryEmptyList() {
    return (
        <div>
            <h5 className="history-result-header">No history records found.</h5>
        </div>
    );
}

export default PatientAppointmentHistory;