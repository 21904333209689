import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LoadingIndicator from '../../components/loadingIndicator';
import ForgotPassword from '../../components/user/forgotPassword';
import * as userActions from '../../actions/userActions';
import ErrorBoundary from '../../components/common/errorBoundary';

class ForgotPasswordView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email:'',
            isPageLoading: false,
            showSuccess: false,
            showError: false,
        }

        this.sendResetEmail = this.sendResetEmail.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
    }

    sendResetEmail = (email) => {
        this.setState({isPageLoading: true});
        this.props.actions.forgotPassword(email)
            .then((response) => {
                if (response.error) {
                    this.setState({
                        isPageLoading: false,
                        error: 'There was an error sending the forgot password email.',
                        showSuccess: false,
                        showError: true,
                    });
                } else {
                    this.setState({
                        isPageLoading: false,
                        error: '',
                        showSuccess: true,
                        showError: false,
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    isPageLoading: false,
                    error: 'There was an error sending the forgot password email.',
                    showSuccess: false,
                    showError: true,
                });
            });
    }

    goToLogin = () => {
        this.props.history.push('/login');
    }

    render() {
        return (
			<div style={{ margin: "30px" }}>
				<ErrorBoundary childName="Forgot Password">
                <h4 style={{"marginBottom" : "20px"}}>Forgot Password</h4>
                    <div className="col-lg-5 col-md-7 col-sm-12" style={{padding: "20px"}}>
                        {this.state.isPageLoading &&
                            <LoadingIndicator />
                        }
                        {!this.state.isPageLoading &&
                            <ForgotPassword
                                onSubmit={this.sendResetEmail}
                                onCancel={this.goToLogin}
                                showSuccess={this.state.showSuccess && this.props.forgotPasswordActionResponse.succeeded}
                                showError={this.state.showError && !this.props.forgotPasswordActionResponse.succeeded} />
                        }
					</div>
				</ErrorBoundary>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        forgotPasswordActionResponse: state.user.forgotPasswordAction,
     }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordView)
