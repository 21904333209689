import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import { ConfirmOrCancelModal } from "components/common/standardComponents";
import PatientAppointmentHistory from "./patientAppointmentHistory";
import PatientCareOrderHistory from "./patientCareOrderHistory";
import { patientDetails as routeToPatientDetails } from '../../../routes';
import PatientDetailsSummary from "./patientDetailsSummary";
import PatientInfoNav from "./patientInfoNav";
import LoadingIndicator from "components/loadingIndicator";
import useLoadPatientAppointmentHistory from "../hooks/useLoadPatientAppointmentHistory";
import useLoadPatientCareOrderHistory from "../hooks/useLoadPatientCareOrderHistory";
import useLoadPatientOpenWorkflows from "../hooks/useLoadPatientOpenWorkflows";
import PatientOpenworkflows from "./patientOpenWorkflows";

export enum PatientInfoTabIds {
    PatientDetails = '1',
    AppointmentHistory = '2',
    CareOrderHistory = '3',
    DecisionSupportWorkflowHistory = '4',
}

export default function PatientInfoTabbedCollapsable(
    { isOpen, toggleIsOpen }: { isOpen: boolean, toggleIsOpen: Function }
) {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(PatientInfoTabIds.PatientDetails);
    const [showConfirmMorePatientInfoModal, setShowConfirmMorePatientInfoModal] = useState(false);

    const patient = useSelector((state: any) => state.activePatient);
    const patientDetails = patient.details;
    const patientConfig = useSelector((state: any) => state.config.patient);
    const schedulingConfig = useSelector((state: any) => state.config.scheduling);

    const appointmentHistoryRecords = useSelector((state: any) => state.activePatient.history);
    const [isPatientAppointmentHistoryLoading, setIsPatientAppointmentHistoryLoading] = useState(false);
    const shouldLoadPatientAppointmentHistory = patientConfig.isPatientHistoryEnabled
        && patientConfig.showPatientHistoryInPatientInfoPanel
        && !appointmentHistoryRecords
        && !isPatientAppointmentHistoryLoading;

    useLoadPatientAppointmentHistory(
        patient.id,
        shouldLoadPatientAppointmentHistory,
        setIsPatientAppointmentHistoryLoading
    );

    const careOrders = useSelector((state: any) => state.activePatient.careOrderHistory);
    const [isPatientCareOrderHistoryLoading, setIsPatientCareOrderHistoryLoading] = useState(false);
    const shouldLoadPatientCareOrderHistory = patientConfig.isPatientCareOrderHistoryEnabled
        && !careOrders
        && !isPatientCareOrderHistoryLoading;

    useLoadPatientCareOrderHistory(
        patient.id,
        shouldLoadPatientCareOrderHistory,
        setIsPatientCareOrderHistoryLoading
    );

    const [isPatientOpenWorkflowsLoading, setIsPatientOpenWorkflowsLoading] = useState(false);
    const shouldLoadPatientOpenWorkflows = patientConfig.isPatientOpenWorkflowsEnabled;
    useLoadPatientOpenWorkflows(
        patient.id,
        shouldLoadPatientOpenWorkflows,
        setIsPatientOpenWorkflowsLoading,
        schedulingConfig.bookOpenDecisionSupportWorkflowMaxAgeInDays,
    )

    return (
        <Card>
            <PatientInfoHeader patientDetails={patientDetails} isOpen={isOpen} toggleIsOpen={toggleIsOpen} />
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <PatientInfoNav
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        patientConfig={patientConfig}
                    />
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={PatientInfoTabIds.PatientDetails}>
                            <PatientDetailsSummary
                                patientDetails={patientDetails}
                                patientConfig={patientConfig}
                                setShowConfirmMorePatientInfoModal={setShowConfirmMorePatientInfoModal}
                            />
                        </TabPane>
                        {patientConfig.isPatientHistoryEnabled && patientConfig.showPatientHistoryInPatientInfoPanel &&
                            <TabPane tabId={PatientInfoTabIds.AppointmentHistory}>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        {isPatientAppointmentHistoryLoading &&
                                            <div>
                                                <LoadingIndicator />
                                            </div>
                                        }
                                        {!isPatientAppointmentHistoryLoading &&
                                            <PatientAppointmentHistory
                                                showHeading={false}
                                                showLoseProgressWarning={true}
                                                enableShowPSAandMHDfilters={false}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                        }
                        {patientConfig.isPatientCareOrderHistoryEnabled &&
                            <TabPane tabId={PatientInfoTabIds.CareOrderHistory}>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        {isPatientCareOrderHistoryLoading &&
                                            <div>
                                                <LoadingIndicator />
                                            </div>
                                        }
                                        {!isPatientCareOrderHistoryLoading &&
                                            <PatientCareOrderHistory
                                                showHeading={false}
                                                showBookButton={false}
                                                showCloseButton={false}
                                                showFilters={false}
                                                setIsPatientCareOrderHistoryLoading={setIsPatientCareOrderHistoryLoading}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                        }
                        {patientConfig.isDecisionSupportWorkflowHistoryEnabled &&
                            <TabPane tabId={PatientInfoTabIds.DecisionSupportWorkflowHistory}>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        {isPatientOpenWorkflowsLoading &&
                                            <div>
                                                <LoadingIndicator />
                                            </div>
                                        }
                                        {!isPatientOpenWorkflowsLoading &&
                                            <PatientOpenworkflows
                                                showHeading={false}
                                                showLoseProgressWarning={true}
                                                showBookButton={schedulingConfig.enableBookOpenDecisionSupportWorkflow}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                        }
                    </TabContent>
                    <ConfirmOrCancelModal
                        className={"confirmMoreInfoModal"}
                        title={"Confirm"}
                        body={"If you continue you will lose your current progress. Are you sure you want to leave?"}
                        isOpen={showConfirmMorePatientInfoModal}
                        confirmLabel={"Confirm"}
                        cancelLabel={"Cancel"}
                        onConfirm={() => {
                            setShowConfirmMorePatientInfoModal(false);
                            dispatch(routeToPatientDetails(patientDetails?.referenceId));
                        }}
                        onCancel={() => setShowConfirmMorePatientInfoModal(false)}
                    />
                </CardBody>
            </Collapse>
        </Card>
    );
}

const PatientInfoHeader = ({ patientDetails, isOpen, toggleIsOpen }: { patientDetails: any, isOpen: any, toggleIsOpen: any }) => (
    <CardHeader>
        <h4 onClick={() => toggleIsOpen()} className="hand" style={{ marginBottom: '0px' }}>
            {`${patientDetails?.firstName} ${patientDetails?.lastName}`}
            <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={isOpen ? faChevronDown : faChevronRight} size="sm" />
        </h4>
    </CardHeader>
);
