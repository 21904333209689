import { fetchPatientOpenWorkflows } from "actions/rd2RefactorActions";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

export const loadPatientOpenWorkflows = async (
    dispatch: any,
    referenceId: any,
    setIsPatientOpenWorkflowsLoading: Function,
    isMounted: () => boolean, // Add a parameter to check if the component is still mounted
    bookOpenDecisionSupportWorkflowMaxAgeInDays: number,
) => {
    try {
        setIsPatientOpenWorkflowsLoading(true);
        await dispatch(fetchPatientOpenWorkflows(referenceId, bookOpenDecisionSupportWorkflowMaxAgeInDays));
    } finally {
        if (isMounted()) {
            setIsPatientOpenWorkflowsLoading(false);
        }
    }
};

const useLoadPatientOpenWorkflows = (
    referenceId: string,
    shouldLoad: boolean,
    setIsPatientOpenWorkflowsLoading: Function,
    bookOpenDecisionSupportWorkflowMaxAgeInDays: number,
) => {

    const dispatch = useDispatch();
    const mountedRef = useRef(true); // Use a ref to track the mounted status so we don't do
    // something like setIsPatientAppointmentHistoryLoading(false) on an unmounted component
    // aka it was loading in the background and no component cares if its done loading

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        // This function checks if the component is still mounted
        const isMounted = () => mountedRef.current;

        if (!shouldLoad) {
            return;
        }

        loadPatientOpenWorkflows(dispatch, referenceId, setIsPatientOpenWorkflowsLoading, isMounted, bookOpenDecisionSupportWorkflowMaxAgeInDays);

    }, [referenceId, shouldLoad, dispatch, setIsPatientOpenWorkflowsLoading, bookOpenDecisionSupportWorkflowMaxAgeInDays]);


};

export default useLoadPatientOpenWorkflows;